<template>
  <section class="login_way fcsbc p4050">
    <div class="way frsac">
      <div class="way_item" :class="{'active': currentIndex == index}" v-for="(item, index) in way" :key="index" @click="wayItemClick(index)">
        {{item.name}}
      </div>
    </div>

    <div class="user_info">
      <div class="sun_code_login" v-show="currentIndex === 0">
        <div class="sun_code p5" @click="getSunCode">
          <img :src="sunCode">
        </div>
        <p>打开微信扫码登录</p>
      </div>
      <div class="phone_login" v-show="currentIndex === 1">
        <div class="phone frsbc p015">
          <div class="left frfsc">
            <span>+ 86</span>
            <img class="ml5" src="~assets/images/down.png">
          </div>
          <div class="right">
            <input name="phone" type="text" v-model="loginData.phoneNumber" maxlength="11">
          </div>
        </div>
        <div class="verification_code frsbc p015">
          <div class="left">
            <input name="verificationCode" type="text" v-model="loginData.verificationCode" placeholder="验证码" maxlength="6" @keyup.enter="login">
          </div>
          <div class="right pl15" @click="getVerificationCode">{{isGet ? `${seconds}s后再次获取` : '获取验证码'}}</div>
        </div>
        <div class="login">
          <div @click="login">立即登录</div>
          <p @click="serviceAgreement">未注册手机验证后自动登录，登录即同意<span>《青葫芦用户服务协议》</span></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    setPcLoginKey
  } from 'utils/user.js';
  import {
    getVerificationCode
  } from 'api/user.js';

  export default {
    created() {
      this.getSunCode();
    },

    destroyed() {
      clearInterval(this.timer);
    },

    data() {
      return {
        way: [
          {name: '微信登录', value: 1},
          {name: '手机登录', value: 2}
        ],
        currentIndex: 0,
        isGet: false,
        timer: null,
        secondsTimer: null,
        seconds: 60,
        loginData: {
          loginSource: 'CYAN_GOURD',
          phoneNumber: '',
          verificationCode: ''
        },
        sunCode: ''
      }
    },

    methods: {
      wayItemClick(index) {
        this.currentIndex = index;
      },
      getVerificationCode() {
        if(this.isGet) {
          return;
        }
        this.isGet = true;
        this.secondsTimer = setInterval(() => {
          this.seconds --;
        }, 1000);
        getVerificationCode({
          phone: this.loginData.phoneNumber
        });
      },
      login() {
        this.$store.dispatch('phoneLogin', this.loginData).then(async res => {
          if(res.code) {
            this.$message.warning(res.message);
            return;
          }
          await this.$store.dispatch('getUserIdentity');
          await this.$store.dispatch('getUserInfo').then(() => {
            this.$router.push({
              name: 'home'
            });
          });
          clearInterval(this.timer);
        });
      },
      getSunCode() {
        clearInterval(this.timer);
        this.$store.dispatch('getSunCode').then(res => {
          this.sunCode = res;
          this.timer = setInterval(() => {
            this.$store.dispatch('sunCodeGetToken').then(async res => {
              if(res.state == 1) {
                this.sunCode = 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1662199468200.png';
              }else if(res.state == 3) {
                this.sunCode = 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1662199496547.png';
                clearInterval(this.timer);
              }
              if(res.token) {
                await this.$store.dispatch('getUserIdentity');
                await this.$store.dispatch('getUserInfo').then(() => {
                  this.$router.push({
                    name: 'home'
                  });
                });
                clearInterval(this.timer);
              }
            });
          }, 1000);
        });
      },
      serviceAgreement() {
        this.$router.push({
          name: 'serviceAgreement'
        });
      }
    },

    watch: {
      seconds(newValue) {
        if(newValue < 0) {
          this.isGet = false;
          clearInterval(this.secondsTimer);
          this.seconds = 60;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .login_way {
    width: 500px;
    height: 570px;
    background: var(--colorfff);
    border-radius: 8px;
    box-shadow: 0px 2px 57px 0px rgba(133, 133, 133, 0.15);
    .way {
      width: 100%;
      border-bottom: 1px solid var(--colorddd);
      .way_item {
        border-bottom: 4px solid transparent;
        padding-bottom: 21px;
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: bold;
        cursor: pointer;
      }
      .active {
        border-bottom: 4px solid var(--colored6d00);
        color: var(--colored6d00);
      }
    }

    .user_info {
      width: 100%;
      .phone_login {
        .phone {
          margin-bottom: 30px;
          height: 50px;
          border: 1px solid var(--colorccc);
          .left {
            span {
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: 400;
            }
            img {
              width: 12px;
              height: 7px;
            }
          }
          .right {
            input {
              width: 300px;
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: 400;
            }
          }
        }
        .verification_code {
          margin-bottom: 100px;
          height: 50px;
          border: 1px solid var(--colorccc);
          .left {
            input {
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: 400;
            }
          }
          .right {
            border-left: 1px solid var(--colorccc);
            font-size: var(--fontSize16);
            color: var(--color1890ff);
            font-weight: 500;
            cursor: pointer;
          }
        }
        .login {
          div {
            height: 64px;
            background: var(--colored6d00);
            font-size: var(--fontSize20);
            color: var(--colorfff);
            font-weight: bold;
            text-align: center;
            line-height: 64px;
            cursor: pointer;
          }
          p {
            margin-top: 18px;
            font-size: var(--fontSize16);
            color: var(--color010101);
            font-weight: 500;
            span {
              color: var(--color2b7de9);
              cursor: pointer;
            }
          }
        }
      }
      .sun_code_login {
        .sun_code {
          margin: 0 auto 30px;
          width: 340px;
          height: 340px;
        }
        p {
          font-size: var(--fontSize16);
          color: var(--color010101);
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
</style>
