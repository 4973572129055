<template>
  <section class="login">
    <div class="content box pt60">
      <NavBar />

      <div class="main_content frsbfs">
        <div class="left">
          <h2 class="mt200">青葫芦<br>图书在线直批平台</h2>
          <p class="mt20">没有中间商赚差价 比网店更便宜</p>
          <div class="mt300" style="line-height: 20px;">地址：福建省福州市仓山区盖山镇盘屿路869号葫芦文化产业集团<br>电话：0591-87448350<br>网站备案号：闽ICP备2022001993号</div>
        </div>
        <div class="right pt100 pr50">
          <LoginWay />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import NavBar from './components/NavBar.vue';
  import LoginWay from './components/LoginWay.vue';

  export default {
    components: {
      NavBar,
      LoginWay
    }
  }
</script>

<style lang="less" scoped>
  .login {
    width: 100vw;
    height: 100vh;
    background: url('~assets/images/login-background.png') no-repeat;
    background-size: 100% 100%;
    .content {
      .main_content {
        .left {
          h2 {
            font-size: var(--fontSize48);
            color: var(--color000);
            font-weight: 800;
          }
          p {
            font-size: var(--fontSize24);
            color: var(--color000);
            font-weight: 400;
          }
          div {
            font-size: var(--fontSize12);
            color: var(--color999);
            font-weight: 400;
          }
        }
        .right {

        }
      }
    }
  }
</style>
