<template>
  <section class="nav_bar frsbc">
    <h1 class="left">
      <img src="~assets/images/logo.png">
    </h1>

    <div class="right">
      <ul class="f">
        <li @click="goHome">进入主页</li>
        <li class="m015">|</li>
        <el-popover trigger="hover" placement="bottom" width="150">
          <img src="~assets/images/sun-code.jpg">
          <li slot="reference">微信小程序</li>
        </el-popover>
        <!-- <li class="m015">|</li>
        <li @click="contact">大宗采购联系</li> -->
      </ul>
    </div>
  </section>
</template>

<script>
  export default {
    methods: {
      goHome() {
        this.$router.push({
          name: 'home'
        });
      },
      contact() {
        console.log('大宗采购联系');
      }
    }
  }
</script>

<style lang="less" scoped>
  .nav_bar {
    height: 55px;
    .left {
      width: 184px;
      height: 55px;
    }

    .right {
      ul {
        li {
          font-size: var(--fontSize16);
          cursor: pointer;
        }
      }
    }
  }
</style>
